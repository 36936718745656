import React, { createContext, useReducer } from "react"

const initState = {}

const reducer = (state, newState) => ({
  ...state,
  ...{ [newState.id]: newState.isVisible },
})

export const ActiveScrollContext = createContext()

export const ActiveScrollProvider = ({ children }) => (
  <ActiveScrollContext.Provider value={useReducer(reducer, initState)}>
    {children}
  </ActiveScrollContext.Provider>
)
