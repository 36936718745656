// Maps the type of Prismic document to proper path for URL
// TODO: replace all hardcoded instances of these paths in other sections of the app with a reference to this resolver so it's centralized

const pathResolver = ({ type, uid }) => {
  if (!type || !uid) return "/"
  if (!type && uid) return `/${uid}`
  switch (type) {
    case "client_result":
      return `/client-result/${uid}`
    case "service":
      return `/service/${uid}`
    case "profile":
      return `/team/${uid}`
    case "news":
      return `/news/${uid}`
    case "legal":
      return `/legal/${uid}`
    case "insight":
      return `/resources/${uid}`
    case "homepage":
      return "/"
    default:
      return `/${uid}`
  }
}

export default pathResolver
