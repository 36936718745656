/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import Helmet from "react-helmet"
import { graphql, StaticQuery } from "gatsby"
import { withPreview } from "@prismicio/gatsby-source-prismic-graphql"

export const query = graphql`
  query {
    allSite {
      edges {
        node {
          siteMetadata {
            author
            description
            title
          }
        }
      }
    }
  }
`

const renderSeo = props => data => {
  const { description, title, imageUrl } = props
  const meta = {}
  const doc = data.allSite.edges.slice(0, 1).pop()
  const metaDescription = description
    ? description
    : doc
    ? doc.node.siteMetadata.description
    : ""

  return (
    <Helmet
      title={
        title
          ? `${title} | ${doc && doc.node.siteMetadata.title}`
          : `${doc && doc.node.siteMetadata.title}`
      }
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title
            ? `${title} | ${doc && doc.node.siteMetadata.title}`
            : `${doc && doc.node.siteMetadata.title}`,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: doc && doc.node.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title
            ? `${title} | ${doc && doc.node.siteMetadata.title}`
            : `${doc && doc.node.siteMetadata.title}`,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: imageUrl
            ? imageUrl
            : "https://images.prismic.io/crgwebsite/456f7d3c-fbb1-456a-b379-25394671b71f_social-card-default.png",
        },
        {
          property: `twitter:image`,
          content: imageUrl
            ? imageUrl
            : "https://images.prismic.io/crgwebsite/456f7d3c-fbb1-456a-b379-25394671b71f_social-card-default.png",
        },
        {
          property: `image`,
          content: imageUrl
            ? imageUrl
            : "https://images.prismic.io/crgwebsite/456f7d3c-fbb1-456a-b379-25394671b71f_social-card-default.png",
        },
      ].concat(meta)}
    />
  )
}

export default props => {
  return (
    <>
      <StaticQuery
        query={query}
        render={withPreview(renderSeo(props), query)}
      />
    </>
  )
}
