import { graphql, StaticQuery } from "gatsby"
import React from "react"
import { Location } from "@reach/router"
import { withPreview } from "@prismicio/gatsby-source-prismic-graphql"
import HeaderContent from "./HeaderContent"
import { RichText } from "prismic-reactjs"
import pathResolver from "../utils/pathResolver"

export const query = graphql`
  {
    prismic {
      allMainnavs {
        edges {
          node {
            main_nav_items {
              nav_item {
                ... on PRISMIC_About {
                  page_title
                  _meta {
                    type
                    uid
                  }
                }
                ... on PRISMIC_Client_results {
                  page_title
                  _meta {
                    type
                    uid
                  }
                }
                ... on PRISMIC_News_landing_page {
                  page_title
                  _meta {
                    type
                    uid
                  }
                }
                ... on PRISMIC_Insights {
                  page_title
                  _meta {
                    type
                    uid
                  }
                }
                ... on PRISMIC_Contact {
                  page_title
                  _meta {
                    type
                    uid
                  }
                }
                ... on PRISMIC_Services {
                  page_title
                  _meta {
                    type
                    uid
                  }
                }
                ... on PRISMIC_Legal {
                  page_title
                  _meta {
                    type
                    uid
                  }
                }
                ... on PRISMIC_Profile {
                  page_title: name
                  _meta {
                    type
                    uid
                  }
                }
                ... on PRISMIC_News {
                  page_title: title
                  _meta {
                    type
                    uid
                  }
                }
                ... on PRISMIC_Client_result {
                  page_title: title
                  _meta {
                    type
                    uid
                  }
                }
                ... on PRISMIC_Capabilities {
                  page_title
                  _meta {
                    type
                    uid
                  }
                }
                ... on PRISMIC_Insight {
                  page_title: title
                  _meta {
                    type
                    uid
                  }
                }
                ... on PRISMIC_Service {
                  page_title: seo_page_title
                  _meta {
                    type
                    uid
                  }
                }
                ... on PRISMIC_Culture {
                  page_title
                  _meta {
                    type
                    uid
                  }
                }
                ... on PRISMIC_Spotlight {
                  page_title
                  _meta {
                    type
                    uid
                  }
                }
                ... on PRISMIC_Team {
                  page_title
                  _meta {
                    type
                    uid
                  }
                }
                ... on PRISMIC_Homepage {
                  page_title: seo_page_title
                  _meta {
                    type
                    uid
                  }
                }
                ... on PRISMIC_Careers {
                  page_title
                  _meta {
                    type
                    uid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const renderHeader = ({ siteTitle }) => data => {
  const doc = data.prismic.allMainnavs.edges.slice(0, 1).pop()
  if (!doc) return null
  const links = data.prismic.allMainnavs.edges[0].node.main_nav_items
    .map(item => {
      const key = item.nav_item._meta.uid
      const stub = pathResolver(item.nav_item._meta)
      const displayName = item.nav_item.page_title
        ? RichText.asText(item.nav_item.page_title)
        : ""
      return { key, stub, displayName }
    })
    .filter(item => !!item.displayName)

  return (
    <Location>
      {({ location }) => <HeaderContent links={links} location={location} />}
    </Location>
  )
}

export default props => {
  return (
    <>
      <StaticQuery
        query={query}
        render={withPreview(renderHeader(props), query)}
      />
    </>
  )
}
