import React, { useState, useCallback } from "react"
import { Link } from "gatsby"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import MenuIcon from "@material-ui/icons/Menu"
import Logo from "../images/logo_primary_light.svg"
import styled from "styled-components"

const StyledHeader = styled("header")`
  padding: 1rem;
  display: flex;
  justify-content: center;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.16);
  z-index: 10 !important;
  background: white;
  position: sticky;
  top: 0;

  .inner-container {
    flex: 0 1 1200px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
  }

  .logo-container {
    flex: 0 0 271.339px;

    svg {
      width: 271.339px;
      height: 60.853px;
    }
  }

  .mobile-nav {
    position: absolute;
    top: -10000px;
  }

  @media screen and (max-width: 768px) {
    .desktop-nav {
      display: none;
    }
    .mobile-nav {
      display: block;
      position: static;
      top: 0;
    }
  }
`

const StyledNav = styled("nav")`
  flex: 0 0 auto;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
  }

  li {
    flex: 0 0 auto;
    margin-left: 2rem;

    a {
      color: #454545;
      text-decoration: none;
      transition: color 0.3s ease;
    }

    .active {
      color: #0099cb;
      border-bottom: solid 3px #0099cb;
    }

    &:hover {
      a {
        color: #0099cb;
      }
    }
  }
`

const MobileNav = styled("div")`
  display: block;
  flex: 0 0 auto;
`

const MobileNavMenu = styled("div")`
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.16);
  background: white;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-out;
  z-index: 9;

  @media screen and (min-width: 769px) {
    display: none;
  }

  @media screen and (max-width: 768px) {
    display: block;
  }

  ul {
    margin: 2rem 0;
    padding: 0;
    list-style: none;
  }

  li {
    padding: 1rem;
    transition: color 0.3s ease;

    a {
      color: #454545;
      text-decoration: none;
    }

    .active {
      color: #0099cb;
      border-bottom: solid 3px #0099cb;
    }

    &:hover {
      color: #0099cb;
    }
  }
`

const HeaderContent = ({ links, location }) => {
  const [showMobileNav, setShowMobileNav] = useState(false)

  const handleNavClick = useCallback(() => {
    const newVal = showMobileNav ? false : true
    setShowMobileNav(newVal)
  }, [showMobileNav])

  return (
    <>
      <StyledHeader>
        <div className="inner-container">
          <div className="logo-container" style={{ margin: 0 }}>
            <Link to="/">
              <Logo alt="Carly Rian Group" title="Carly Rian Group" />
            </Link>
          </div>
          <StyledNav className="desktop-nav">
            <ul>
              {links.map(l => {
                let isActive = location.pathname.startsWith(`${l.stub}`)
                return (
                  <li key={l.key}>
                    <Link to={l.stub} className={isActive ? "active" : ""}>
                      {l.displayName}
                    </Link>
                  </li>
                )
              })}
            </ul>
          </StyledNav>
          <MobileNav className="mobile-nav">
            {showMobileNav ? (
              <IconButton id="closebtn" onClick={handleNavClick}>
                <CloseIcon />
              </IconButton>
            ) : (
              <IconButton id="openbtn" onClick={handleNavClick}>
                <MenuIcon />
              </IconButton>
            )}
          </MobileNav>
        </div>
      </StyledHeader>
      <MobileNavMenu
        className="mobile-nav-menu"
        style={{
          transform: showMobileNav ? "translateY(90px)" : "translateY(-100%)",
        }}
      >
        <ul>
          {links.map(l => {
            let isActive = location.pathname.startsWith(`${l.stub}`)
            return (
              <li key={l.key}>
                <Link to={l.stub} className={isActive ? "active" : ""}>
                  {l.displayName}
                </Link>
              </li>
            )
          })}
        </ul>
      </MobileNavMenu>
    </>
  )
}

export default HeaderContent
