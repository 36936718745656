/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { withPreview } from "@prismicio/gatsby-source-prismic-graphql"
import styled from "styled-components"
import Header from "./header"
import Footer from "./Footer"
import "./layout.css"
import { ActiveScrollProvider } from "../hooks/ActiveScrollContext"
import { ThemeProvider } from "@material-ui/core/styles"
import theme from "../theme"

const MainContainer = styled("main")`
  .block-img {
    img {
      width: 100%;
      max-width: 900px;
      height: auto;
    }
  }

  .embed {
    iframe {
      width: 100%;
      height: 500px;

      @media screen and (max-width: 480px) {
        height: 275px;
      }
    }
  }
`

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default ({ children, mainContainerStyles }) => {
  return (
    <StaticQuery
      query={query}
      render={withPreview(data => {
        return (
          <>
            <ActiveScrollProvider>
              <ThemeProvider theme={theme}>
                <Header siteTitle={data.site.siteMetadata.title} />
                <MainContainer style={mainContainerStyles}>
                  {children}
                </MainContainer>
                <Footer></Footer>
                <div id="form-portal"></div>
              </ThemeProvider>
            </ActiveScrollProvider>
          </>
        )
      }, query)}
    />
  )
}
