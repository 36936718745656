import { Link, StaticQuery, graphql } from "gatsby"
import React from "react"
import Logo from "../images/logo_icon_primary_light.svg"
import styled from "styled-components"
import { withPreview } from "@prismicio/gatsby-source-prismic-graphql"
import { RichText } from "prismic-reactjs"
import pathResolver from "../utils/pathResolver"

const Container = styled("div")`
  background: #463f4a;
  padding: 2rem;
  display: flex;
  justify-content: center;
  color: #fff;

  .inner-container {
    flex: 0 1 1200px;
    max-width: 1200px;
  }
`

const StyledNav = styled("nav")`
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: center;
  }

  li {
    flex: 0 0 auto;
    margin: 0 3rem;

    a {
      color: #fff;
      text-decoration: none;
    }
  }

  @media screen and (max-width: 768px) {
    ul {
      flex-wrap: wrap;
    }

    li {
      font-size: 0.8rem;
      margin: 0 1rem 0.5rem;
    }
  }
`

const LogoContainer = styled("div")`
  margin: 2rem;
  text-align: center;

  .copyright {
    font-size: 0.75rem;
    font-weight: 300;
  }
`

const TermsLinks = styled("div")`
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: center;
    font-size: 0.75rem;
    font-weight: 300;
  }

  li {
    flex: 0 0 auto;
    margin: 0 3rem;

    a {
      color: #fff;
      text-decoration: none;
    }
  }

  @media screen and (max-width: 768px) {
    ul {
      flex-wrap: wrap;
    }

    li {
      margin: 0 1rem;
    }
  }
`

export const query = graphql`
  {
    prismic {
      allMainnavs {
        edges {
          node {
            main_nav_items {
              nav_item {
                ... on PRISMIC_About {
                  page_title
                  _meta {
                    type
                    uid
                  }
                }
                ... on PRISMIC_Client_results {
                  page_title
                  _meta {
                    type
                    uid
                  }
                }
                ... on PRISMIC_News_landing_page {
                  page_title
                  _meta {
                    type
                    uid
                  }
                }
                ... on PRISMIC_Insights {
                  page_title
                  _meta {
                    type
                    uid
                  }
                }
                ... on PRISMIC_Contact {
                  page_title
                  _meta {
                    type
                    uid
                  }
                }
                ... on PRISMIC_Services {
                  page_title
                  _meta {
                    type
                    uid
                  }
                }
                ... on PRISMIC_Legal {
                  page_title
                  _meta {
                    type
                    uid
                  }
                }
                ... on PRISMIC_Profile {
                  page_title: name
                  _meta {
                    type
                    uid
                  }
                }
                ... on PRISMIC_News {
                  page_title: title
                  _meta {
                    type
                    uid
                  }
                }
                ... on PRISMIC_Client_result {
                  page_title: title
                  _meta {
                    type
                    uid
                  }
                }
                ... on PRISMIC_Capabilities {
                  page_title
                  _meta {
                    type
                    uid
                  }
                }
                ... on PRISMIC_Insight {
                  page_title: title
                  _meta {
                    type
                    uid
                  }
                }
                ... on PRISMIC_Service {
                  page_title: seo_page_title
                  _meta {
                    type
                    uid
                  }
                }
                ... on PRISMIC_Culture {
                  page_title
                  _meta {
                    type
                    uid
                  }
                }
                ... on PRISMIC_Spotlight {
                  page_title
                  _meta {
                    type
                    uid
                  }
                }
                ... on PRISMIC_Team {
                  page_title
                  _meta {
                    type
                    uid
                  }
                }
                ... on PRISMIC_Homepage {
                  page_title: seo_page_title
                  _meta {
                    type
                    uid
                  }
                }
                ... on PRISMIC_Careers {
                  page_title
                  _meta {
                    type
                    uid
                  }
                }
              }
            }
          }
        }
      }
      allFooters {
        edges {
          node {
            copyright_notice
          }
        }
      }
    }
  }
`

const renderFooter = data => {
  const doc = data.prismic.allMainnavs.edges.slice(0, 1).pop()
  if (!doc) return null
  const links = data.prismic.allMainnavs.edges[0].node.main_nav_items
    .map(item => {
      const key = item.nav_item._meta.uid
      const stub = pathResolver(item.nav_item._meta)
      const displayName = item.nav_item.page_title
        ? RichText.asText(item.nav_item.page_title)
        : ""
      return { key, stub, displayName }
    })
    .filter(item => !!item.displayName)
  const footerDoc = data.prismic.allFooters.edges.slice(0, 1).pop()
  const copyrightNotice = footerDoc.node.copyright_notice
  return (
    <Container>
      <div className="inner-container">
        <StyledNav>
          <ul>
            {links.map(l => (
              <li key={l.key}>
                <Link to={l.stub}>{l.displayName}</Link>
              </li>
            ))}
          </ul>
        </StyledNav>
        <LogoContainer>
          <Logo />
          <p className="copyright">{RichText.asText(copyrightNotice)}</p>
        </LogoContainer>
        <TermsLinks>
          <ul>
            <li>
              <Link to="/legal/privacy-policy">Privacy Policy</Link>
            </li>
            <li>
              <Link to="/legal/terms-of-use">Terms of Use</Link>
            </li>
            <li>
              <Link to="/legal/accessibility">Accessibility</Link>
            </li>
          </ul>
        </TermsLinks>
      </div>
    </Container>
  )
}
export default () => {
  return (
    <>
      <StaticQuery query={query} render={withPreview(renderFooter, query)} />
    </>
  )
}
